body {
  margin: 20px 0;
  font-family: sans-serif !important; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1a1d27;
  color: #d2d2d2;
  font-size: 14px;
  line-height: 1.42857143;
}
h1 {
  color: #fff;
}
h1 .fa {
  color: rgb(249, 211, 99);
}
.vertical-center {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
h1 {
  font-size: 36px;
  text-align: center;
}
h2 {
  font-size: 24px;
}
.ethInput {
  padding: 20px 0;
}
.ethInput input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  color: #555;
  line-height: 1.42857143;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
.ethInput button, .ethInput button:focus {
  color: #fff;
  border: 1px solid rgb(249, 211, 99) 0% !important;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 4px;
  background: linear-gradient(122deg, rgb(249, 211, 99) 0%, rgba(255, 226, 142, 1) 100%) !important;
} 
.ethInput button:hover {
  color: #fff;
  border: 1px solid rgb(249, 211, 99) !important;
  background: rgb(249, 211, 99);
  opacity: 0.8;
}
.width10 {
  width: 10%;
}
.width20 {
  width: 20%;
}
.width30 {
  width: 30%;
}
.width40 {
  width: 40%;
}
.width50 {
  width: 50%;
}
.width60 {
  width: 60%;
}
.width70 {
  width: 70%;
}
.width80 {
  width: 80%;
}
.width90 {
  width: 90%;
}
.width100 {
  width: 100%;
}
.requestsTable_box {
  margin-top: 15px;
  margin-bottom: 60px !important;
}
.requestsTable td, .requestsTable th {
  padding: 0.25rem 0.28rem;
}
.requestsTable tr img {
  width: 35px;
  height: 35px;
  border-radius: 5px;
}
.requestsTable_addr pre {
  background: #1a1d27;
  padding: 5px 0;
  border: 1px solid rgb(249, 211, 99)69;
  border-radius: 5px;
  font-size: 13px;
  text-align: center;
  margin-top: 2px;
  margin-bottom: 0;
}
pre {
  color: #fff;
}
.requestsTable .progress {
  height: 5px;
  width: 150px;
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none !important;
}
.dropdown-menu a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333 !important;
  font-size: 14px;
  white-space: nowrap;
}
::placeholder { 
  color: #999ba6 !important;
  opacity: 1; 
}

:-ms-input-placeholder { 
  color: #999ba6 !important;
}

::-ms-input-placeholder { 
  color: #999ba6 !important;
}

.panel-default {
  border-color: #ddd;
}
.panel {
  margin-bottom: 20px;
  background-color: #000;
  border: 1px solid rgb(249, 211, 99);
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}
.table td, .table th {
  padding: 0.75rem 0.25rem;
  vertical-align: top;
  border-top: 1px solid rgb(249, 211, 99)80;
}
.panel-body {
  padding: 0;
  overflow: auto;
  max-height: 300px;
}
.panel-footer {
  padding: 10px 4px;
  background: linear-gradient(122deg, rgb(249, 211, 99) 0%, rgba(255, 226, 142, 1) 100%);
  border-top: 1px solid rgb(249, 211, 99) 0%;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}
.panel-footer td {
  padding: 10px 0;
  display: inline-table;
  margin-right: 4px;
  color: #ffffff;
  font-size: 15px;
}
.reqText {
  padding-left: 40px;
}
.reqText dt {
  display: flex;
  font-weight: 400;
}
.reqText .fa {
  font-size: 35px;
  width: 50px;
}
.reqText a {
  color: #337ab7;
  text-decoration: none;
}
.reqText a:hover {
  color: #337ab7;
  text-decoration: underline;
}
.ethInput input {
  background: #000000;
  border: 1px solid rgb(249, 211, 99) 0%;
  color: rgb(249, 211, 99) 0% !important;
  height: 40px;
}
.ethInput .dropdown-item, .ethInput .dropdown-item:focus {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  color: #1a1d27;
  text-align: left;
}
.ethInput .dropdown-item:hover {
  background-color: #eaeef1 !important;
  border: 1px solid transparent !important;
  color: #000;
  text-align: left;
  outline: none !important;
}